import React, { ReactElement } from "react"
import DateTime from "@utils/DateTime"
const style = require("./index.module.css")

function HeroContainer(): ReactElement {
  const dt1 = DateTime.Moment(new Date()).format("LL")
  const dt2 = DateTime.Moment(new Date())
    .add(7, "d")
    .format("LL")

  const heading = (
    <>
      <h1 className="text-3xl md:text-5xl">CAR HIRE UK</h1>
      <h5 className="inline-block text-sm bg-yellow-500 text-yellow-800 rounded-full px-4">
        * Covid-19 Safety Measures & News
      </h5>
      <h3 className="text-xl md:text-2xl my-2">Starting from GBP 14 per day*</h3>
      <h6 className="text-xs my-2">
        * Inclusive of all taxes based on 7 days rental plan from {dt1} to {dt2}
      </h6>
    </>
  )
  return (
    <div className="">
      <div
        style={{ backgroundImage: `url(${require("@images/clouds.svg")})` }}
        className={`${style.wrapper} bg-blue-500 text-white md:px-4 bg-no-repeat bg-right-top`}
      >
        <div className="max-w-1366 mx-auto relative">
          <div
            className={`flex flex-col-reverse md:flex-row flex-wrap items-start  md:py-8`}
          >
            <div className="md:flex-1 flex-shrink-0 hidden md:block m-4">
              {heading}
            </div>
            <div className="flex justify-end relative w-full md:w-auto">
              <div
                style={{
                  backgroundColor: "rgba(104,134,197,0.9)",
                }}
                className="rounded-md text-white px-8 py-16 md:hidden absolute w-full h-full z-10 flex justify-center"
              >
                <div className="w-full h-full">{heading}</div>
              </div>
              <img src={require("@images/illustration.svg")} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeroContainer
