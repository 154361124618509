import React, { ReactElement } from "react"

interface Props {
  title?: string
  items: any[]
}

function Strap({ title, items }: Props): ReactElement {
  return (
    <div
      className="relative max-w-1366 w-full mx-auto bg-gray-300  flex items-center overflow-hidden"
      style={{ minHeight: "150px" }}
    >
      <div
        className="absolute h-full bg-blue-600"
        style={{
          width: "350px",
          zIndex: 0,
          transform: "skew(-15deg)",
          left: "-100px",
        }}
      />

      <div className="absolute w-full h-full flex  px-8">
        <div className="flex justify-center items-center text-white">
          <h3 className="text-2xl">{title}</h3>
        </div>
        <div className="hidden flex-1 md:flex h-full justify-evenly items-center flex-wrap">
          {items.map((i: any, index: number) => {
            return (
              <div key={index} className="flex justify-center items-center">
                <img className="mr-2" src={i.imageURL} />
                <h3 className="text-md">{i.text}</h3>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Strap
