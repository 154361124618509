import React, { ReactElement } from "react"
import HeroContainer from "@hoc/Hero"
import SearchBar from "@hoc/SearchBar"
import CommonUtils from "@utils/CommonUtils"
import { navigate } from "gatsby"
import Strap from "@components/Strap"
import HowToInfo from "@hoc/HowToInfo"

function HomeContainer(): ReactElement {
  const onSearchClick = (query: any) => {
    const queryString = CommonUtils.jsonToQueryString(
      JSON.parse(JSON.stringify(query))
    )
    navigate("/search?" + queryString)
  }

  const process = [
    {
      imageURL: require("@images/search.svg"),
      title: "Search",
      subTitle:
        "Use the search bar to find a ride best suiting your convinience and timing.",
    },
    {
      imageURL: require("@images/book.svg"),
      title: "Book",
      subTitle: "Fill up a simple form and book your ride with us instantly.",
    },
    {
      imageURL: require("@images/pickup.svg"),
      title: "Ride",
      subTitle: "Grab keys of your ride on scheduled date and time.",
    },
  ]

  return (
    <div>
      <HeroContainer />
      <div className="max-w-1366 mx-auto">
        <div className="flex pr-0 md:pr-16 -mt-0 md:-mt-16 z-10 md:w-3/4 w-full  ">
          <div className=" bg-gray-200 w-full rounded-lg shadow-lg p-4">
            <SearchBar spread={true} onSearchClick={onSearchClick} />
          </div>
        </div>
      </div>
      <div className=" bg-gray-200 w-full my-16">
        <Strap
          items={[
            {
              imageURL: require("@images/check_icon.svg"),
              text: "Lowest time to possession",
            },
            {
              imageURL: require("@images/check_icon.svg"),
              text: "Fastest verifications",
            },
            {
              imageURL: require("@images/check_icon.svg"),
              text: "Ride now, Pay later",
            },
          ]}
          title="Why choose us?"
        />
      </div>
      <div className="max-w-1366 mx-auto ">
        <div className="hidden md:block text-center mb-16 mt-16 md:pl-8">
          <h3 className="text-3xl">Easy Process</h3>
        </div>
        <div className="flex flex-wrap mb-8 ">
          {process.map((p: any) => (
            <>
              <div className="w-full md:w-1/3 px-8 flex-col flex-wrap justify-center">
                <div className=" flex justify-center items-center">
                  <img className="w-64 h-64" src={p.imageURL} />
                </div>
                <div className="my-8 flex text-center flex-col justify-center items-center">
                  <h3 className="text-2xl mb-2">{p.title}</h3>
                  <h5 className="text-sm">{p.subTitle}</h5>
                </div>
              </div>
            </>
          ))}
        </div>
      </div>
      <div className=" bg-gray-200 w-full my-16">
        <Strap
          items={[
            {
              imageURL: require("@images/check_icon.svg"),
              text: "Select your ride",
            },
            {
              imageURL: require("@images/check_icon.svg"),
              text: "Choose your collection time",
            },
            {
              imageURL: require("@images/check_icon.svg"),
              text: "Pickup form nearest location",
            },
          ]}
          title="How to hire?"
        />
        <div className="max-w-1366 mx-auto">
          <HowToInfo />
        </div>
      </div>
    </div>
  )
}

export default HomeContainer
